import { useState } from "react"
import BtnPrimary from "../../../../../../components/buttons/BtnPrimary"
import SimpleLoader from "../../../../../../components/loaders/SimpleLoader"
import { createList } from "../../services/ListNumbers.services"
import { StyledModalCreateList, StyledModalForm } from "./styled"

const ModalCreateList = () => {
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);
    const [nameFile, setNameFile] = useState("");

    const uploadSingleFile = (e: React.ChangeEvent<HTMLInputElement>) => {
        if(e.currentTarget && e.currentTarget.files && e.currentTarget.files[0] ){
            setNameFile(e.currentTarget.files[0].name)
        }
    }

    const sendForm = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if(!e.currentTarget.csv.files || !e.currentTarget.csv.files[0]){
            setError("Suba el archivo csv")
            return;
        }
        if(e.currentTarget.listName.value.length <= 7){
            setError("Ingrese el nombre")
            return;
        }
        const formData = new FormData();
        formData.append('listName', e.currentTarget.listName.value);
        formData.append('csv', e.currentTarget.csv.files[0]);
        setLoading(true)
        createList(formData)
            .then( resp => {
                if(resp.status === 200) {
                    window.location.href = "/listas"
                }
            } )
            .catch( err => {
                setLoading(false)
                console.log(err)
                setError("Ocurrio un error")
            } )
            
    }

    
    if(loading){
        return(
            <StyledModalCreateList>
                <p>CREAR LISTA</p>
                <SimpleLoader/>
            </StyledModalCreateList>
        )
    }

    return(
        <StyledModalCreateList>
            <p>CREAR LISTA</p>
            <StyledModalForm onSubmit={sendForm}>

                <label className="dropCSV" htmlFor="uploadCSV">
                    <i className="fas fa-file-csv fa-3x"></i>
                    <p>{nameFile ? nameFile : "SUBE LA LISTA EN CSV"}</p>
                </label>
                <input onChange={uploadSingleFile} hidden type="file" name="csv" id="uploadCSV" accept=".csv"/>
                <span className="span">
                    Usa el formato de lista csv,                        
                    <a 
                        href="https://docs.google.com/spreadsheets/d/15kPTCh9izReJrWh6WeIp9h9lywC-Xh7EMeKtzWpj1ag/edit?usp=sharing" 
                        target="_blank"
                    >
                        AQUI
                    </a>
                </span>
                <input 
                autoComplete="off" placeholder="NOMBRE" 
                className="form-input" 
                type="text" name="listName"/>
                <p className="text-error"> {error} </p>
                <BtnPrimary action={() => {}} text="CREAR" />
            </StyledModalForm>
        </StyledModalCreateList>
    )
}

export default ModalCreateList