import styled from "styled-components";
import { PRIMARY_COLOR, SECONDARY_COLOR, TEXT_COLOR } from "../../../styled-componets/variables";

export const StyledAdminHeader = styled.header`
    position: fixed;
    top: 0;
    width: 100%;
    background-color: ${PRIMARY_COLOR};
    display: flex;
    height: 80px;
    justify-content: space-between;
    padding: 0.5rem 1rem;
    align-items: center;
    z-index: 100;
    p{
        color: ${TEXT_COLOR};
    }
`

export const StyledItemProfile = styled.div`
    width: 100%;
    max-width: 220px;
    background-color: ${SECONDARY_COLOR};
    display: grid;
    grid-template-columns: 50px 1fr 30px;
    align-items: center;
    border-radius: 0.2rem;
    .btn-exit{
        color: ${TEXT_COLOR};
        cursor: pointer;
        margin: 0 5px;
    }
    p{
        text-align: start;
    }
    img{
        width: 40px;
        height: 40px;
        margin: 5px;
        border-radius: 50%;
    }
`

export const StyledAdminAside = styled.aside`
    position: fixed;
    margin-top: 80px;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    max-width: 200px;
    background-color: ${PRIMARY_COLOR};
`

export const StyledAdminAsideUL = styled.ul`
    padding-left: 0;
    a{
        color: ${TEXT_COLOR};
        text-decoration: none;
        &.active{
            .menu-item{
                background-color: ${SECONDARY_COLOR};
            }
        }
    }
    .menu-item{
        width: 100%;
        display: flex;
        padding: 1rem 0.5rem;
        justify-content: start;
        align-items: center;
        p{
            margin: 0;
            margin-left: 5px;
            font-weight: bold;
        }
        &:hover{
            background-color: ${SECONDARY_COLOR};
        }
    }
`