import DataTable, { ExpanderComponentProps } from "react-data-table-component";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import SimpleLoader from "../../../../components/loaders/SimpleLoader";
import HandleLink from "../../../../containers/HandleLink";
import WrapperAdmin from "../../components/wrappers/WrapperAdmin"
import ModalDetailStatus from "./components/modals/ModalDetailStatus";
import { IDetailShipment, IlogShipment } from "./interfaces/DetailShipment";
import { getDetailShipment } from "./services/DetailShipment.services";
import { StyledBodyDetailShipment, StyledContainerDetailShipment, StyledHeaderDetailShipment } from "./styles"

const cols = [
    {
        name: "ID",
        selector: (row: any) => row.id
    },
    {
        name: "FECHA",
        selector: (row: any) =>  {
            return(
                <div className="row-date">
                    <p>{new Date(row.date).toLocaleDateString()}</p>
                    <p>{new Date(row.date).toLocaleTimeString()}</p>
                </div>
            )
        },
    },
    {
        name: "NOMBRE",
        selector: (row: any) => row.name
    },
    {
        name: "NUMERO",
        selector: (row: any) => row.number
    },
    {
        name: "ESTADO",
        selector: (row: any) => {
            if(row.status === 0) {
                return <p className="item item-wait"><i className="far fa-clock"></i> EJECUTADO</p>
            }
            if(row.status === 1) {
                return <p className="item item-active"><i className="fas fa-check-square"></i> ENVIADO</p>
            }
            if(row.status === 2) {
                return <p className="item item-error"><i className="fas fa-exclamation-triangle"></i> ERROR</p>
            }
            return <p className="item item-read"><i className="fas fa-check-double"></i> LEIDO</p>
        }
    }
]

const ExpandedComponent: React.FC<ExpanderComponentProps<IlogShipment>> = ({ data }) => {
    return <ModalDetailStatus DataShip={data}  />;
};

const DetailShipment = () => {
    const {idShipment} = useParams();
    const { isLoading, isError, data } = useQuery<IDetailShipment>('DetailShipment', async () => getDetailShipment(idShipment as string))


    if(isLoading){
        return(
            <WrapperAdmin>
                <SimpleLoader/>
            </WrapperAdmin>
        )
    }

    if(isError || !data){
        return(
            <WrapperAdmin>
                <p>Ocurrio un Error</p>
            </WrapperAdmin>
        )
    }

    return(
        <WrapperAdmin>
            <StyledContainerDetailShipment>
                <StyledHeaderDetailShipment>
                    <HandleLink href="/envios">
                        <p><i className="fas fa-arrow-left"></i>&nbsp;Regresar </p>
                    </HandleLink>
                    <p>MIS ENVIOS | {idShipment} </p>
                </StyledHeaderDetailShipment>
                <StyledBodyDetailShipment>
                    <div className="container-preview">
                        <p>PREVIEW MENSAJE</p>
                        <p>Proximamente</p>
                    </div>
                    <div className="container-table">
                        <div className="container-summary">
                            <div className="summary">
                                <p><i className="fas fa-angle-right"></i>&nbsp;Total: </p>
                                <p> {data.summary.totalNumbers || "0"} </p>
                            </div>
                            <div className="summary item-active">
                                <p><i className="fas fa-check-square"></i>&nbsp;Enviados: </p>
                                <p> {data.summary.totalSends || "0"} </p>
                            </div>
                            <div className="summary item-read">
                                <p><i className="fas fa-check-double"></i>&nbsp;Leidos: </p>
                                <p> {data.summary.totalRead || "0"} </p>
                            </div>
                            <div className="summary item-error">
                                <p><i className="fas fa-exclamation-triangle"></i>&nbsp;Error: </p>
                                <p> {data.summary.totalErrors || "0"} </p>
                            </div>
                        </div>
                        <DataTable
                            columns={cols}
                            data={data.data}
                            responsive
                            pagination 
                            expandableRows
                            expandableRowsComponent={ExpandedComponent}
                        />
                    </div>
                </StyledBodyDetailShipment>
            </StyledContainerDetailShipment>
        </WrapperAdmin>
    )
}

export default DetailShipment