import styled from "styled-components";

export const StyledContainerAccounts = styled.div`
    width: 100%;
`

export const StyledHeaderAccounts = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    p{
        font-weight: bold;
        font-size: 14px;
    }
`


export const StyledWrapperAccounts = styled.div`
    width: 100%;
    padding: 1rem 0;
    display: flex;
    justify-content: start;
    align-items: flex-start;
    flex-wrap: wrap;
`
