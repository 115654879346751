import WhatsAppLogo from "../../assets/logo.png"
import { StyledLogo } from "./styles"

interface props {
    width: number
    height: number
}

const Logo = ({width,height}:props) => {
    return(
        <StyledLogo width={width}>
            <img src={WhatsAppLogo} alt="whatsapp" width={width} height={height} />
            <p>PRECISO</p>
        </StyledLogo>
    )
}

export default Logo