import BtnPrimary from "../../../../../components/buttons/BtnPrimary"
import HandleModal from "../../../../../containers/HandleModal"
import ModalCreateList from "./modals/ModalCreateList"

const BtnAddList = () => {
    return(
        <HandleModal Modal={ModalCreateList}>
            {
                (HandleModal) => <BtnPrimary action={HandleModal} text="+ CREAR LISTA"/>
            }
        </HandleModal>
    )
}

export default BtnAddList