import styled from "styled-components";
import { PRIMARY_COLOR, TEXT_COLOR } from "../../../../styled-componets/variables";

export const StyledContainerCreateShipment = styled.div`
    width: 100%;
`

export const StyledHeaderCreateShipment = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    p{
        font-weight: bold;
        font-size: 14px;
    }
`


export const StyledBody = styled.div`
    width: 100%;
    .box-items{
        width: 100%;
        display: flex;
        justify-content: center;
        align-items:center;
        flex-wrap: wrap;
    }
    .item{
        padding: 2rem;
        margin: 0.5rem 2rem;
        background-color: #25D366;
        color: ${TEXT_COLOR};
        font-weight: bold;
        border-radius: 5px;
        cursor: pointer;
        transition: all .2s;
        &:hover{
            scale: 1.02;
            background-color: #075E54;
        }
    }
`