import WrapperAdmin from "../../components/wrappers/WrapperAdmin";

const Home = () => {
  return (
    <WrapperAdmin>
      <div>
        <h1>APP WHATSAPP</h1>
        <p>COMO USAR EL APLICATIVO</p>
        <iframe
          width="560"
          height="315"
          src="https://www.youtube.com/embed/xoDxwNU4-O8"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      </div>
    </WrapperAdmin>
  );
};

export default Home;
