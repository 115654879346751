import styled from "styled-components";

export const StyledModalCreateAccount= styled.div`
    width: 100%;
    p{
        display: block;
        text-align: start;
        margin: 5px 0;
    }
`

export const StyledModalForm= styled.form`
    width: 100%;
    .text-error{
        color: firebrick;
        font-size: 12px;
        margin: 5px 0;
    }
    .form-input{
        width: 100%;
        max-width: 420px;
        height: 50px;
        background-color: #F2F2F2;
        outline: none;
        border: none;
        border-radius: 5px;
        margin: 0.5rem 0;
        padding: 0.5rem;
    }
`