import { useState } from "react"
import BtnPrimary from "../../../../../../components/buttons/BtnPrimary"
import SimpleLoader from "../../../../../../components/loaders/SimpleLoader"
import useForm from "../../../../../../hooks/useForm"
import { FormCreateAccount } from "../../interfaces/Account"
import { createAccount } from "../../services/Accounts.services"
import { StyledModalCreateAccount, StyledModalForm } from "./styled"

const ModalCreateAccount = () => {
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);
    const { form,handleForm } = useForm<FormCreateAccount>({
        name: "",
        metaID: ""
    })

    const sendForm = (e: React.FormEvent) => {
        e.preventDefault();
        setLoading(true)
        
        createAccount(form)
            .then( resp => {
                if(resp.status === 200) {
                    window.location.href = "/cuentas"
                }
            } )
            .catch( err => {
                setLoading(false)
                console.log(err)
                setError("Ocurrio un error")
            } )
            
    }

    
    if(loading){
        return(
            <StyledModalCreateAccount>
                <p>CREAR CUENTA</p>
                <SimpleLoader/>
            </StyledModalCreateAccount>
        )
    }

    return(
        <StyledModalCreateAccount>
            <p>CREAR CUENTA</p>
            <StyledModalForm onSubmit={sendForm}>
                <input 
                onChange={handleForm}
                autoComplete="off" placeholder="NOMBRE" 
                value={form.name} className="form-input" 
                type="text" name="name"/>
                <input 
                onChange={handleForm}
                autoComplete="off" placeholder="ID META" 
                value={form.metaID} className="form-input" 
                type="text" name="metaID"/>
                <p className="text-error"> {error} </p>
                <BtnPrimary action={() => {}} text="CREAR" />
            </StyledModalForm>
        </StyledModalCreateAccount>
    )
}

export default ModalCreateAccount