import HandleLink from "../../../../containers/HandleLink"
import WrapperAdmin from "../../components/wrappers/WrapperAdmin"
import { StyledBody, StyledContainerCreateShipment, StyledHeaderCreateShipment } from "./styles"

const CreateShipment = () => {
    return (
        <WrapperAdmin>
            <StyledContainerCreateShipment>
                <StyledHeaderCreateShipment>
                    <p>MIS ENVIOS | CREAR ENVIO</p>
                </StyledHeaderCreateShipment>
                <StyledBody>
                    <h2>SELECCIONA EL MOTOR DE ENVIO</h2>
                    <div className="box-items">
                        <HandleLink href="/envios/crear-envio/api" >
                            <div className="item">
                                <i className="fab fa-whatsapp fa-2x"></i>
                                <p>WhatsApp API</p>
                            </div>
                        </HandleLink>
                        <div className="item">
                            <i className="fas fa-robot fa-2x"></i>
                            <p>WhatsApp BOT</p>
                        </div>
                    </div>
                </StyledBody>
            </StyledContainerCreateShipment>
        </WrapperAdmin>
    )
}

export default CreateShipment