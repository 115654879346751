import DataTable from "react-data-table-component"
import { useQuery } from "react-query"
import { useParams } from "react-router-dom"
import SimpleLoader from "../../../../components/loaders/SimpleLoader"
import WrapperAdmin from "../../components/wrappers/WrapperAdmin"
import { IDetailList } from "./interfaces/DetailList"
import { getDetailListNumbers } from "./services/DetailList.services"
import { StyledContainerDetailList, StyledHeaderDetailList } from "./styles"

const cols = [
    {
        name: "ID",
        selector: (row: any) => row.id_phone
    },
    {
        name: "NOMBRE",
        selector: (row: any) => row.name
    },
    {
        name: "NUMERO",
        selector: (row: any) => row.number
    },
    {
        name: "ESTADO",
        selector: (row: any) => {
            if(row.status === 1) {
                return <p className="item-active"><i className="fas fa-check-square"></i> Activo</p>
            }
            return <p className="item-error"><i className="fas fa-exclamation-triangle"></i> Inactivo</p>
        }
    }
]

const DetailList = () => {
    const { idList } = useParams();
    const { isLoading, isError, data } = useQuery<IDetailList>('DetailList', async () => getDetailListNumbers(idList as string))

    if (isLoading) {
        return (
            <WrapperAdmin>
                <SimpleLoader />
            </WrapperAdmin>
        )
    }

    if (isError || !data) {
        return (
            <WrapperAdmin>
                <p>Ocurrio un Error</p>
            </WrapperAdmin>
        )
    }

    return (
        <WrapperAdmin>
            <StyledContainerDetailList>
                <StyledHeaderDetailList>
                    <p>MIS LISTAS | {data.id} - {data.name.toUpperCase()} </p>
                </StyledHeaderDetailList>
                <DataTable
                    columns={cols}
                    data={data.phones}
                    responsive
                    pagination 
                />
            </StyledContainerDetailList>
        </WrapperAdmin>
    )
}

export default DetailList