import { useQuery } from "react-query"
import { List } from "../interfaces/Admin.interfaces"
import { getListNumbers } from "../services/Admin.services"

interface props {
    Loader: () => JSX.Element
    children: ({data}: {data: List[]}) => JSX.Element
}

const GetListsContainer = ({Loader, children}:props) => {
    const { isLoading, isError, data } = useQuery<List[]>('List', getListNumbers)

    if(isLoading){
        return(
            <Loader/>
        )
    }

    if(isError){
        return(
            <p>Upps ocurrio un error</p>
        )
    }

    if(!data || data.length === 0){
        return(
            <p>No tienes listas</p>
        )
    }

    return children({data})
}

export default GetListsContainer