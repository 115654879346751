import axios from "axios";
import config from "../../../config";
import { CreateHeader } from "../../../utils/Http";

const URL = config.API_URL

export const getAccounts = async () => {
    const header = CreateHeader();
    const resp = await axios.get(`${URL}/api/accounts/getAccounts`,header)
    if(resp.status == 200) return resp.data.data;
    return [];
}

export const getListNumbers = async () => {
    const header = CreateHeader();
    const resp = await axios.get(`${URL}/api/phones/getListPhones`,header)
    if(resp.status == 200) return resp.data.data;
    return [];
}