import { BrowserRouter as Router } from "react-router-dom";
import SimpleLoader from "../components/loaders/SimpleLoader";
import ScrollToTop from "../components/scrollToTop";
import ContextAuth from "../contexts/Auth.context";
import AdminRouter from "./Admin.router";
import AuthRouter from "./Auth.router";

const RootRouter = () => {
    const { dataUser,loading } = ContextAuth();

    if(loading){
        return(
            <SimpleLoader/>
        )
    }

    return (
        <Router basename="/">
            {dataUser.auth ? <AdminRouter /> : <AuthRouter />}
            <ScrollToTop />
        </Router>
    );
}

export default RootRouter