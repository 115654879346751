import styled from "styled-components";

export const StyledFadeModal = styled.div`
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.51);
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
`

export const StyledContainerBaseModal = styled.div`
    width: 100%;
    max-width: ${ (props) => props.maxWidth ? props.maxWidth : 400}px;
    background-color: white;
    border-radius: 5px;
    padding: 1rem;
    .close-modal{
        display: block;
        text-align: end;
        cursor: pointer;
    }
`