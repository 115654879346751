import styled from "styled-components";

export const StyledContainerLists = styled.div`
    width: 100%;
`
export const StyledHeaderLists = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    p{
        font-weight: bold;
        font-size: 14px;
    }
`

export const StyledWrapperLists = styled.div`
    width: 100%;
    padding: 1rem 0;
    display: flex;
    justify-content: start;
    align-items: flex-start;
    flex-wrap: wrap;
    a{
        text-decoration: none;
        color: black;
    }
`
