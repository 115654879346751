import SimpleLoader from "../../../../../components/loaders/SimpleLoader"
import GetListsContainer from "../../../containers/GetLists.container"
import { StyledWrapperLists } from "../styles"
import CardListNumber from "./cards/CardListNumber"

const WrapperLists = () => {

    return(
        <StyledWrapperLists>
            <GetListsContainer Loader={SimpleLoader}>
                {
                    ({data}) => {
                        return (
                            <>
                                {data.map( (list) => <CardListNumber key={list.id_list} {...list} /> )}
                            </>
                        )
                    }
                }
            </GetListsContainer>
        </StyledWrapperLists>
    )
}

export default WrapperLists