import styled from "styled-components";

export const StyledContainerADMwrapper = styled.div`
    width: 100%;
`

export const StyledBody = styled.div`
    width: 100%;
    padding: 1rem;
    padding-left: 210px;
    padding-top: 90px;

`