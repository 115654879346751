import BtnPrimary from "../../../../../components/buttons/BtnPrimary"
import HandleModal from "../../../../../containers/HandleModal"
import ModalCreateAccount from "./modals/ModalCreateAccount"

const BtnAddAccount = () => {
    return(
        <HandleModal Modal={ModalCreateAccount}>
            {
                (HandleModal) => <BtnPrimary action={HandleModal} text="+ CREAR CUENTA"/>
            }
        </HandleModal>
    )
}

export default BtnAddAccount