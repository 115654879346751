import AdminAside from "../../layouts/AdminAside"
import AdminHeader from "../../layouts/AdminHeader"
import { StyledBody, StyledContainerADMwrapper } from "./styles"

interface props {
    children: JSX.Element
}

const WrapperAdmin = ({children}:props) => {
    return(
        <StyledContainerADMwrapper>
            <AdminHeader/>
            <AdminAside/>
            <StyledBody>
                {children}
            </StyledBody>
        </StyledContainerADMwrapper>
    )
}

export default WrapperAdmin