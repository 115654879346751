import axios from "axios";
import { LoginForm, RegisterForm } from "../interfaces/Auth.interfaces";
import { CreateHeader } from "../utils/Http";

const URL = process.env.REACT_APP_API_URL

export const sendLogin = (form: LoginForm) => {
    return axios.post(`${URL}/api/auth/login`,form)
}

export const sendRegister = (form: RegisterForm) => {
    return axios.post(`${URL}/api/auth/register`,form)
}

export const getMyProfile = () => {
    const header = CreateHeader();
    return axios.get(`${URL}/api/auth/me`,header)
}