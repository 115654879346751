import axios from "axios";
import config from "../../../../../config";
import { CreateHeader } from "../../../../../utils/Http";
//import { FormCreateAccount } from "../interfaces/ListNumbers";
const URL = config.API_URL


export const createList= (form: FormData) => {
    const header = CreateHeader();
    return axios.post(`${URL}/api/phones/createListPhones`,form,header)
}