import styled from "styled-components";

export const StyledTextArea = styled.div`
    width: 100%;
    .text-area{
        width: 100%;
        resize: none;
        outline: none;
        padding: 0.2rem;
        border-radius: 5px;
        border: 1px solid #ccc;
        font-size: 0.8rem;
        min-height: 50px;
        text-align: start;
        position: relative;
        &:empty::before{
            position: absolute;
            content: "${props => props.placeholder ? props.placeholder : "" }";
        }  
        div{
            white-space: pre-wrap;
        }
    }
`