import styled from "styled-components";

export const StyledLogo = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    p{
        font-weight: bold;
        font-size: ${ props => props.width/2 }px;
    }
    img{
        margin: 5px;
    }
`