import styled from "styled-components";

export const StyledCardAccount = styled.div`
    display: grid;
    grid-template-columns: 50px 200px;
    margin-right: 1rem;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    padding: 1rem;
    img{
        width: 50px;
        height:50px;
        border-radius: 50%;
    }
    cursor: pointer;
    transition: scale .2s;
    &:hover{
        scale: 1.02;
    }
`

export const StyledBodyCardAccount = styled.div`
    text-align: start;
    p{
        margin: 5px;
        font-size: 14px;
    }
    .title{
        font-weight: bold;
        text-transform: uppercase;
    }
    .subtitle{
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
`