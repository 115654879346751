import Cookies from "js-cookie";
import Logo from "../../../components/Logos/Logo"
import config from "../../../config";
import ContextAuth from "../../../contexts/Auth.context";
import { StyledAdminHeader, StyledItemProfile } from "./styles"

const AdminHeader = () => {
    const { dataUser } = ContextAuth();

    const Logout = () => {
        Cookies.remove(config.TOKEN_AUTH)
        window.location.href = "/"
    }

    return(
        <StyledAdminHeader>
            <Logo width={40} height={40} />
            <StyledItemProfile>
                { 
                dataUser.photo 
                    ? <img src={dataUser.photo} alt={dataUser.fullname} />
                    : <img 
                    src={`https://ui-avatars.com/api/?name=${dataUser.name}+${dataUser.lastname}&background=random&color=white`} 
                    alt={dataUser.fullname} />
                }
                <p>{dataUser.fullname.toUpperCase()}</p>
                <div onClick={Logout} className="btn-exit" title="salir"><i className="fas fa-sign-out-alt"></i></div>
            </StyledItemProfile>
        </StyledAdminHeader>
    )
}

export default AdminHeader