import styled from "styled-components";


export const StyledContainerLogin = styled.div`
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    min-height: 100vh;
    .title{
        font-size: 1.5rem;
        margin: 5px 0;
        font-weight: 500;
    }
    form{
        width: 100%
    }
    .text-error{
        color: firebrick;
        font-size: 12px;
        margin: 5px 0;
    }
    .form-input{
        width: 100%;
        max-width: 420px;
        height: 50px;
        background-color: #F2F2F2;
        outline: none;
        border: none;
        border-radius: 5px;
        margin: 0.5rem 0;
        padding: 0.5rem;
    }
`
