import SimpleLoader from "../../../../../components/loaders/SimpleLoader"
import GetAcccountsContainer from "../../../containers/GetAccounts.container"
import { StyledWrapperAccounts } from "../styles"
import CardAccount from "./cards/CardAccount"

const WrapperAccounts = () => {
    return(
        <StyledWrapperAccounts>
            <GetAcccountsContainer Loader={SimpleLoader}>
                {
                    ({data}) => {
                        return (
                            <>
                                {data.map( (account) => <CardAccount key={account.account_id} {...account} /> )}
                            </>
                        )
                    }
                }
            </GetAcccountsContainer>
        </StyledWrapperAccounts>
    )
}

export default WrapperAccounts