import styled from "styled-components";

export const StyledContainerTemplateMessage = styled.div`
    width: 100%;
    max-width: 600px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin: 1rem auto;
    font-size: 0.8rem;
    .preview-msg{
        width: 100%;
        background-color: whitesmoke;
        padding: 0.4rem;
        .img{
            width: 100%;
            border-radius: 5px;
            object-fit: cover;
        }
        .msg p{
            margin: 1px 0;
            text-align: start;
            word-break: break-all;
        }
        .span{
            width: 100%;
            margin: 0;
            margin-top: 10px;
            text-align: end;
            border-top: 1px solid black;
            padding: 0.5rem 0;
            font-weight: bold;
        }
    }
    .text-error{
        color: firebrick;
        font-weight: bold;
        font-size: 12px;
    }
    .form-msg{
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 0 0.5rem;
        .form-select,.form-input{
            width: 100%;
            padding: 0.5rem;
            margin: 5px 0;
            cursor: pointer;
            border: 1px solid #ccc;
            outline: none;
        }
    }
`