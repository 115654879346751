import styled from "styled-components";

export const StyledModalDetailStatus = styled.div`
    ul{
        padding-left: 0;

    }
    li{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
    }
`