import styled from "styled-components";
import { PRIMARY_COLOR, SECONDARY_COLOR, TEXT_COLOR } from "../../../../styled-componets/variables";

export const StyledContainerInput = styled.div`
    width: 100%;
    position: relative;
    input{
        padding-right: 10px;
    }
    .password-visible{
        position: absolute;
        top: 22px;
        right: 10px;
        cursor: pointer;
    }
`

export const StyledButton = styled.button`
    width: 100%;
    max-width: 120px;
    padding: 0.8rem 1rem;
    margin: 10px 0;
    outline: none;
    border: none;
    background-color: ${PRIMARY_COLOR};
    color: ${TEXT_COLOR};
    border-radius: 0.2rem;
    cursor: pointer;
    &:hover{
        background-color: ${SECONDARY_COLOR};
    }
`