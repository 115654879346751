import DataTable from "react-data-table-component"
import { useQuery } from "react-query"
import BtnPrimary from "../../../../components/buttons/BtnPrimary"
import SimpleLoader from "../../../../components/loaders/SimpleLoader"
import HandleLink from "../../../../containers/HandleLink"
import WrapperAdmin from "../../components/wrappers/WrapperAdmin"
import { Shipment } from "./interfaces/Shipments"
import { getShipments } from "./services/Shipments.service"
import { StyledContainerShipments, StyledHeaderShipments } from "./styles"

const cols = [

    {
        name: "ID",
        selector: (row: any) => row.id
    },
    {
        name: "FECHA",
        selector: (row: any) =>  {
            return(
                <div className="row-date">
                    <p>{new Date(row.date).toLocaleDateString()}</p>
                    <p>{new Date(row.date).toLocaleTimeString()}</p>
                </div>
            )
        }
    },
    {
        name: "CUENTA",
        selector: (row: any) => row.account
    },
    {
        name: "TIPO",
        selector: (row: any) => row.template
    },
    {
        name: "LISTA",
        selector: (row: any) => row.namelist
    },
    {
        name: "RESUMEN",
        selector: (row: any) => {
            return(
                <div className="row-summary">
                    <p className="item " title="total"><i className="fas fa-angle-right"></i>&nbsp;{row.totalNumbers} </p>
                    <p className="item item-active" title="enviados"><i className="fas fa-check-square"></i> {row.totalSends}</p>
                    <p className="item item-read" title="leidos"><i className="fas fa-check-double"></i>&nbsp;{row.totalReads}</p>
                    <p className="item item-error" title="errores"><i className="fas fa-exclamation-triangle"></i>&nbsp;{row.totalErrors} </p>
                </div>
            )
        } 
    },
    {
        name: "ESTADO",
        selector: (row: any) => {
            if(row.status === 0) {
                return <p className="item-wait"><i className="far fa-clock"></i> ENVIANDO</p>
            }
            if(row.status === 1) {
                return <p className="item-active"><i className="fas fa-check-square"></i> ENVIADO</p>
            }
            return <p className="item-error"><i className="fas fa-exclamation-triangle"></i> ERROR</p>
        }
    },
    {
        name: "",
        selector: (row: any) => {
            return(
                <div className="item-btn">
                    <HandleLink href={`/envios/${row.id}`} >
                        <><i className="far fa-eye"></i> VER</>
                    </HandleLink>
                </div>
            )
        }
    }
]

const Shipments = () => {
    const { isLoading, isError, data } = useQuery<Shipment[]>('shipments', getShipments)


    if (isLoading) {
        return (
            <WrapperAdmin>
                <SimpleLoader />
            </WrapperAdmin>
        )
    }

    if (isError) {
        return (
            <WrapperAdmin>
                <p>Ocurrio un Error</p>
            </WrapperAdmin>
        )
    }

    if (!data || data.length === 0) {
        return (
            <WrapperAdmin>
                <StyledContainerShipments>
                    <StyledHeaderShipments>
                        <p>MIS ENVIOS</p>
                        <HandleLink href="/envios/crear-envio">
                            <BtnPrimary action={() => { }} text="+ CREAR ENVIO" />
                        </HandleLink>
                    </StyledHeaderShipments>
                    <p>No hay envios</p>
                </StyledContainerShipments>
            </WrapperAdmin>
        )
    }

    return (
        <WrapperAdmin>
            <StyledContainerShipments>
                <StyledHeaderShipments>
                    <p>MIS ENVIOS</p>
                    <HandleLink href="/envios/crear-envio">
                        <BtnPrimary action={() => { }} text="+ CREAR ENVIO" />
                    </HandleLink>
                </StyledHeaderShipments>
                <DataTable
                    columns={cols}
                    data={data}
                    responsive
                    pagination 
                />
            </StyledContainerShipments>
        </WrapperAdmin>
    )
}

export default Shipments