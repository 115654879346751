import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { AuthContextProvider } from './contexts/Auth.context';
import { GlobalStyle } from './styled-componets/globalStyle';
import { Normalize } from 'styled-normalize'
import {QueryClientProvider, QueryClient } from "react-query"

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const queryClient = new QueryClient();

root.render(
  <React.StrictMode>
    <Normalize />
    <GlobalStyle />
    <QueryClientProvider client={queryClient}>
      <AuthContextProvider>
        <App />
      </AuthContextProvider>
    </QueryClientProvider>
  </React.StrictMode>
);

