import styled from "styled-components";
import { PRIMARY_COLOR, TEXT_COLOR } from "../../../../../../styled-componets/variables";

export const StyledModalCreateList= styled.div`
    width: 100%;
    p{
        display: block;
        text-align: start;
        margin: 5px 0;
    }
`

export const StyledModalForm= styled.form`
    width: 100%;
    .text-error{
        color: firebrick;
        font-size: 12px;
        margin: 5px 0;
    }
    .form-input{
        width: 100%;
        max-width: 420px;
        height: 50px;
        background-color: #F2F2F2;
        outline: none;
        border: none;
        border-radius: 5px;
        margin: 0.5rem 0;
        padding: 0.5rem;
    }
    .span{
        display: block;
        margin: 5px 0;
        width: 100%;
        font-size: 12px;
        text-align: start
    }
    .dropCSV{
        width: 100%;
        display: block;
        padding: 1rem;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 5px;
        cursor: pointer;
        flex-direction: column;
        color: ${TEXT_COLOR};
        background-color: ${PRIMARY_COLOR};
        transition: scale .2s;
        &:hover{
            scale: 1.02;
        }
    }
`