import { StyledContainerBaseModal, StyledFadeModal } from "./styled"

interface props {
    children: JSX.Element | JSX.Element[]
    handleModal: () => void
    maxWidth?: number
}

const BaseModal = ({children,handleModal, maxWidth}:props) => {
    return(
        <StyledFadeModal>
            <StyledContainerBaseModal maxWidth={maxWidth}>
                <i onClick={handleModal} className="fas fa-times fa-2x close-modal"></i>
                {children}
            </StyledContainerBaseModal>
        </StyledFadeModal>
    )
}

export default BaseModal