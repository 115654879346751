import styled from "styled-components";
import { PRIMARY_COLOR, TEXT_COLOR,SECONDARY_COLOR } from "../../styled-componets/variables";

export const StyledBtnPrimary = styled.button`
    background-color: ${ (props) =>  props.color  ? props.color : PRIMARY_COLOR};
    color: ${ (props) =>  props.txtcolor  ? props.txtcolor : TEXT_COLOR};
    padding: 0.6rem 1rem;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
    transition: scale .2s;
    border: none;
    &:hover{
        scale: 1.02;
        background-color:  ${ (props) =>  props.color  ? props.color : SECONDARY_COLOR};
    }
`