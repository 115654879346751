import { useQuery } from "react-query"
import { Account } from "../interfaces/Admin.interfaces"
import { getAccounts } from "../services/Admin.services"

interface props {
    Loader: () => JSX.Element
    children: ({data}: {data: Account[]}) => JSX.Element
}

const GetAcccountsContainer = ({Loader, children}:props) => {
    const { isLoading, isError, data } = useQuery<Account[]>('accounts', getAccounts)

    if(isLoading){
        return(
            <Loader/>
        )
    }

    if(isError){
        return(
            <p>Upps ocurrio un error</p>
        )
    }

    if(!data || data.length === 0){
        return(
            <p>No tienes cuentas</p>
        )
    }

    return children({data})
}

export default GetAcccountsContainer