import { useState } from "react";
import { StyledTextArea } from "./styles";

interface props {
    className?: string
    initText ?: string
    placeholder: string
    name: string
    handleChange ?: (txt: string) => void
}

const TextArea = ({className,initText,placeholder,name,handleChange}:props) => {
    const [text, setText] = useState(initText ? initText : "");

    const handleText = (txt: string) => {
        setText(txt)
        if(handleChange) handleChange(txt)
    }
    
    return (
        <StyledTextArea placeholder={placeholder} >
            <div className={"text-area "+className} contentEditable onInput={(e) => handleText(e.currentTarget.innerText)}></div>
            <textarea hidden name={name} value={text}></textarea>
        </StyledTextArea>
    )
}

export default TextArea