import styled from "styled-components";
import { PRIMARY_COLOR, SECONDARY_COLOR, TEXT_COLOR } from "../../../../styled-componets/variables";

export const StyledContainerShipments = styled.div`
    width: 100%;
    .item-btn{
        background-color: ${PRIMARY_COLOR};
        padding: 0.4rem;
        border-radius: 5px;
        width: 60px;
        text-align: center;
        margin: 0 auto;
        a{  
            font-size: 10px;
            color: ${TEXT_COLOR};
            text-decoration: none;
        }
        &:hover{
            background-color: ${SECONDARY_COLOR};
        }
    }
    .row-date p{
        font-size: 10px;
        margin: 5px;
    }
    .row-summary{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        justify-content: center;
        align-items: center;
        gap: 5px;
        p{
            margin: 5px;
        }
    }
`

export const StyledHeaderShipments = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    p{
        font-weight: bold;
        font-size: 14px;
    }
`


export const StyledWrapperShipments = styled.div`
    width: 100%;
    padding: 1rem 0;
    display: flex;
    justify-content: start;
    align-items: flex-start;
    flex-wrap: wrap;
    
`
