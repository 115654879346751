import Logo from "../../components/Logos/Logo"
import InputPassword from "./components/Inputs/InputPassword"
import { StyledButton } from "./components/Inputs/styles"
import { StyledContainerLogin } from "./styles"
import HandleLink from "../../containers/HandleLink";
import useForm from "../../hooks/useForm";
import { RegisterForm } from "../../interfaces/Auth.interfaces";
import { sendRegister } from "../../services/Auth.services";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import SimpleLoader from "../../components/loaders/SimpleLoader";

const Register = () => {
    const navigate = useNavigate();
    const [error, setError] = useState();
    const [loading, setLoading] = useState(false);
    const { form,handleForm } = useForm<RegisterForm>({
        name: "",
        lastname: "",
        email: "",
        password: "",
        password2: ""
    })

    const sendForm = (e: React.MouseEvent) => {
        e.preventDefault()
        setLoading(true)
        sendRegister(form)
        .then( resp => {
            if(resp.status === 200){
                navigate("/login");
            }
        } )
        .catch( err => {
            setLoading(false) 
            if(err.response && err.response.data){
                setError(err.response.data.message)
            }
        } )
    }

    
    if(loading){
        return(
            <StyledContainerLogin>
                <Logo width={80} height={80} />
                <SimpleLoader/>
            </StyledContainerLogin>
        )
    }

    return (
        <StyledContainerLogin>
            <Logo width={80} height={80} />
            <p className="title">Registrate</p>
            <form>
                <input
                    onChange={handleForm}
                    value={form.name} className="form-input"
                    placeholder="Nombre: " type="text"
                    name="name" autoComplete="off"
                />
                <input
                    onChange={handleForm}
                    value={form.lastname} className="form-input"
                    placeholder="Apellido: " type="text"
                    name="lastname" autoComplete="off"
                />
                <input
                    onChange={handleForm}
                    value={form.email} className="form-input"
                    placeholder="Correo: " type="email"
                    name="email" autoComplete="off"
                />
                <InputPassword
                    name="password"
                    handleChange={handleForm}
                    placeholder="Contraseña: "
                    value={form.password}
                    style="form-input"
                />
                <InputPassword
                    name="password2"
                    handleChange={handleForm}
                    placeholder="Repite la Contraseña: "
                    value={form.password2}
                    style="form-input"
                />
                <p className="text-error"> {error} </p>
                <StyledButton onClick={sendForm}>Registrate</StyledButton>
            </form>
            <p>¿Ya tienes cuenta?&nbsp;<HandleLink href="/login"><span>Inicia session</span></HandleLink></p>
        </StyledContainerLogin>
    )
}

export default Register