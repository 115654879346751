import styled from "styled-components";

export const StyledContainerDetailShipment= styled.div`
    width: 100%;
`
export const StyledHeaderDetailShipment = styled.div`
    width: 100%;
    text-align: start;
    a{
        text-decoration: none;
        color: black;
    }
    p{
        font-weight: bold;
        font-size: 14px;
    }
`
export const StyledBodyDetailShipment = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 2fr;
    margin: 1rem 0;
    .container-preview{
        width: 100%;
        min-height: 400px;
        max-height: 600px;
        background-color: #ccc;
        color: white;
        display: flex; 
        justify-content: center;
        align-items: center;
        flex-direction: column;
        border-radius: 5px;
    }
    .container-summary{
        width: 100%;
        display: grid;
        grid-template-columns: repeat(4, 1fr);
    }
    .summary{
        display: grid;
        grid-template-columns: 2fr 1fr; 
        margin: 10px 0; 
        p{
            margin: 0;
        }
    }
    .container-table{
        width: 100%;
        padding: 0 0.5rem;
    }
`