import { createGlobalStyle } from "styled-components";
import { BG_COLOR } from "./variables";

export const GlobalStyle = createGlobalStyle`
    *{
        box-sizing: border-box;
    }
    body {
        background-color: ${BG_COLOR};
        height: 100vh;
        margin: 0 auto;
        overscroll-behavior: none;
        width: 100%;
        position: relative;
        font-family: 'Roboto', sans-serif;
    }
    .App{
        width: 100%;
        text-align: center;
    }
    .item, .item-active, .item-wait,  .item-error{
        margin: 5px;
        font-size: 12px;
        font-weight: bold;
        text-transform: uppercase;
    }
    .item-active{
        color: #02A443;
    }
    .item-wait{
        color: #D0775B;
    }
    .item-error{
        color: #D05B5B;
    }
    .item-read{
        color: #0B88CE;
    }
`;