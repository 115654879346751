import { useQuery } from "react-query"
import SimpleLoader from "../../../../../../components/loaders/SimpleLoader"
import { IDetailStatus, IlogShipment } from "../../interfaces/DetailShipment"
import { getDetailLog } from "../../services/DetailShipment.services"
import { StyledModalDetailStatus } from "./styles"

interface props {
    DataShip: IlogShipment
}

const ModalDetailStatus = ({ DataShip }: props) => {
    const { isLoading, isError, data } = useQuery<IDetailStatus[]>(`DetailStatus-${DataShip.IDwamid}`, async () => getDetailLog(DataShip.IDwamid))

    if (isLoading) {
        return (
            <SimpleLoader />

        )
    }

    if (isError || !data) {
        return (
            <p>Ocurrio un Error</p>
        )
    }

    return (
        <StyledModalDetailStatus>
            <h2>REGISTROS</h2>
            <ul>
                {
                    data.map((s) => {
                        return (
                            <li>
                                {new Date(s.date).toLocaleDateString()} / {new Date(s.date).toLocaleTimeString()} &nbsp;|&nbsp;
                                {s.status === 0 && <p className="item item-wait"><i className="far fa-clock"></i> EJECUTADO</p>}
                                {s.status === 1 && <p className="item item-active"><i className="fas fa-check-square"></i> ENVIADO</p>}
                                {s.status === 2 && <p className="item item-error"><i className="fas fa-exclamation-triangle"></i> ERROR</p>}
                                {s.status === 3 && <p className="item item-read"><i className="fas fa-check-double"></i> LEIDO</p>}
                            </li>
                        )
                    })
                }
            </ul>
        </StyledModalDetailStatus>
    )
}

export default ModalDetailStatus