import { Template } from "../interfaces/Template.interfaces";

export const ValidateForm = (data:Template) => {
    try {
        if(data.idAccount.length === 0) throw new Error("Seleccione una cuenta")
        if(data.idList.length === 0) throw new Error("Seleccione una lista")
        if(
        data.bodyMessage.paragraph1.length === 0 
        || data.bodyMessage.paragraph2.length === 0
        || data.bodyMessage.paragraph3.length === 0
        || data.bodyMessage.paragraph4.length === 0
        ) {
            throw new Error("Debe completar todos los parrafos")
        }

        const TotalLength = 204+data.bodyMessage.paragraph1.length+data.bodyMessage.paragraph2.length+data.bodyMessage.paragraph3.length+data.bodyMessage.paragraph4.length

        if(TotalLength > 1024) throw new Error("El mensaje es muy largo, maximo 1024 caracteres")

        return {
            error: false,
            msg: "ok"
        }
    } catch (e) {
        return {
            error: true,
            msg: (e as Error).message
        }
    }
}