import RootRouter from "./routes/root.router";

function App() {
  return (
    <div className="App">
      <RootRouter/>
    </div>
  );
}

export default App;
