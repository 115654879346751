import HandleLink from "../../../../../../containers/HandleLink"
import { List } from "../../../../interfaces/Admin.interfaces"
import {  StyledCardList } from "./styles"

const CardListNumber = ({ namelist, total, date,id_list }: List) => {
    return (
        <HandleLink href={`/listas/${id_list}`}>
            <StyledCardList>
                <div className="listcard-header">
                    <p className="title"> {namelist} </p>
                    <p className="time"> {new Date(date).toLocaleDateString()} </p>
                </div>
                <p className="subtitle"> <strong>{total}</strong> {total === 1 ? "numero" :"numeros"} en total </p>
            </StyledCardList>
        </HandleLink>
    )
}

export default CardListNumber