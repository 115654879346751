import { Navigate, useRoutes } from "react-router-dom";
import Accounts from "../pages/Admin/views/Accounts";
import CreateShipment from "../pages/Admin/views/CreateShipment";
import CreateWhatsAppShipment from "../pages/Admin/views/CreateShipment/WhatsAppApi";
import DetailList from "../pages/Admin/views/DetailList";
import DetailShipment from "../pages/Admin/views/DetailShipment";
import Home from "../pages/Admin/views/Home";
import Lists from "../pages/Admin/views/Lists";
import Shipments from "../pages/Admin/views/Shipments";

function AdminRouter() {
    const routes = [
      {
        path: "/",
        element: <Navigate to="/inicio" />,
        /*
        children: [
          {
            path: "/",
            element: <DashboardOverview/>,
          }
        ]*/
      },
      {
        path: "/inicio",
        element: <Home/>,
      },
      {
        path: "/cuentas",
        element: <Accounts/>,
      },
      {
        path: "/envios",
        element: <Shipments/>,
      },
      {
        path: "/envios/:idShipment",
        element: <DetailShipment/>,
      },
      {
        path: "/envios/crear-envio",
        element: <CreateShipment/>,
      },
      {
        path: "/envios/crear-envio/api",
        element: <CreateWhatsAppShipment/>,
      },
      {
        path: "/listas",
        element: <Lists/>,
      },
      {
        path: "/listas/:idList",
        element: <DetailList/>,
      },
      {
        path: "/error-page",
        element: <p>error</p>,
      },
      {
        path: "*",
        element: <Navigate to="/inicio" />,
      }
    ];
  
    return useRoutes(routes);
  }
  
  export default AdminRouter;