import HandleLink from "../../../containers/HandleLink"
import { StyledAdminAside, StyledAdminAsideUL } from "./styles"

const AdminAside = () => {
    return(
        <StyledAdminAside>
            <StyledAdminAsideUL>
                <HandleLink href="/inicio">
                    <li className="menu-item">             
                        <i className="fas fa-home"></i>
                        <p>Inicio</p>
                    </li>
                </HandleLink>
                <HandleLink href="/cuentas">
                    <li className="menu-item">             
                        <i className="fas fa-user-circle"></i>
                        <p>Cuentas</p>
                    </li>
                </HandleLink>
                <HandleLink href="/envios">
                    <li className="menu-item">             
                    <i className="fas fa-paper-plane"></i>
                        <p>Envios</p>
                    </li>
                </HandleLink>
                <HandleLink href="/listas">
                    <li className="menu-item">             
                        <i className="fas fa-address-book"></i>
                        <p>Lista de numeros</p>
                    </li>
                </HandleLink>
            </StyledAdminAsideUL>
        </StyledAdminAside>
    )
}

export default AdminAside