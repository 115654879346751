import styled from "styled-components";

export const StyledContainerDetailList = styled.div`
    width: 100%;
`
export const StyledHeaderDetailList = styled.div`
    width: 100%;
    text-align: start;
    p{
        font-weight: bold;
        font-size: 14px;
    }
`