import axios from "axios";
import config from "../../../../../config";
import { CreateHeader } from "../../../../../utils/Http";

const URL = config.API_URL

export const getDetailShipment = async (idShip: string) => {
    const header = CreateHeader();
    const resp = await axios.get(`${URL}/api/shipments/getDetailShipment/${idShip}`,header)
    if(resp.status == 200) return resp.data;
    return {};
}

export const getDetailLog = async (idWamid: string) => {
    const header = CreateHeader();
    const resp = await axios.post(`${URL}/api/shipments/getDetailLog`, {idWamid},header)
    if(resp.status == 200) return resp.data.data;
    return [];
}