import { Navigate, useRoutes } from "react-router-dom";
import Login from "../pages/Auth/Login";
import Register from "../pages/Auth/Register";

const AuthRouter = () => {
    const routes = [
      {
        path: "/login",
        element: <Login/>,
      },
      {
        path: "/register",
        element: <Register/>,
      },
      {
          path: "*",
          element: <Navigate to="/login" />,
      },
    ];
  
    return useRoutes(routes);
  }
  
  export default AuthRouter;