import styled from "styled-components";

export const StyledCardList = styled.div`
    display:flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    width: 300px;
    margin-right: 1rem;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    padding: 1rem;
    cursor: pointer;
    transition: scale .2s;
    &:hover{
        scale: 1.02;
    }
    p{
        margin: 5px;
        font-size: 14px;
    }
    .listcard-header{
        width: 100%;
        display: grid;
        grid-template-columns: 2fr 1fr;
        align-items: center;
    }
    .title{
        text-align: start;
        font-weight: bold;
        text-transform: uppercase;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
    .subtitle{
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
    .time{
        text-align: end;
        font-size: 10px;
    }
`