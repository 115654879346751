import { useState } from 'react';
import BaseModal from '../components/modals/BaseModal';

interface props {
    children: (handleModal: () => void) => JSX.Element
    Modal: () => JSX.Element
    maxWidth?: number
}

const HandleModal = ({ children,Modal,maxWidth }: props) => {
    const [isOpen, setIsOpen] = useState(false);

    const handleModal = () => setIsOpen(!isOpen)

    return (
        <>
            {isOpen &&
                <BaseModal maxWidth={maxWidth} handleModal={handleModal}>
                    <Modal />
                </BaseModal>
            }
            {children(handleModal)}
        </>
    )
}

export default HandleModal