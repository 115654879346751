import WrapperAdmin from "../../components/wrappers/WrapperAdmin"
import BtnAddList from "./components/BtnAddList"
import WrapperLists from "./components/WrapperLists"
import { StyledContainerLists, StyledHeaderLists } from "./styles"

const Lists = () => {
    return(
        <WrapperAdmin>
            <StyledContainerLists>
                <StyledHeaderLists>
                    <p>MIS LISTAS</p>
                    <BtnAddList/>
                </StyledHeaderLists>
                <WrapperLists/>
            </StyledContainerLists>
        </WrapperAdmin>
    )
}

export default Lists