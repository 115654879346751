import { useState } from "react"
import { useNavigate } from "react-router-dom"
import BtnPrimary from "../../../../../components/buttons/BtnPrimary"
import TextArea from "../../../../../components/inputs/TextArea"
import SimpleLoader from "../../../../../components/loaders/SimpleLoader"
import WrapperAdmin from "../../../components/wrappers/WrapperAdmin"
import GetAcccountsContainer from "../../../containers/GetAccounts.container"
import GetListsContainer from "../../../containers/GetLists.container"
import { StyledBody, StyledContainerCreateShipment, StyledHeaderCreateShipment } from "../styles"
import { sendTemplateMessage } from "./services/WhatsappApi.services"
import { StyledContainerTemplateMessage } from "./styles"
import { ValidateForm } from "./utils/ValidateForm"

const CreateWhatsAppShipment = () => {
    const [paragraphs, setParagraphs] = useState({
        p1: "",
        p2: "",
        p3: "",
        p4: ""
    });
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const handleParagraphs = (key:string, value:string) => {
        setParagraphs({...paragraphs, [key]: value})
    }

    const sendForm = (e: React.FormEvent<HTMLFormElement>) => {
        if(loading) return
        e.preventDefault()
        const data = {
            idAccount: e.currentTarget.accounts.value,
            idList: e.currentTarget.lists.value,
            idTemplate: 1,
            bodyMessage: {
                paragraph1: e.currentTarget.p1.value,
                paragraph2: e.currentTarget.p2.value,
                paragraph3: e.currentTarget.p3.value,
                paragraph4: e.currentTarget.p4.value,
            }
        }

        const resp = ValidateForm(data)
        if(resp.error) setError(resp.msg)
        setLoading(true)
        sendTemplateMessage(data)
        .then( (res) => {
            if(res.status === 200) navigate("/envios")
        } )
        .catch( () => setError("Upss ocurrio un error") )
        .finally( () => setLoading(false) )
    }

    return (
        <WrapperAdmin>
            <StyledContainerCreateShipment>
                <StyledHeaderCreateShipment>
                    <p>MIS ENVIOS | CREAR ENVIO | WHATSAPP API</p>
                </StyledHeaderCreateShipment>
                <StyledBody>
                    <StyledContainerTemplateMessage>
                        <div className="preview-msg">
                            <img className="img" src="https://www.mediafire.com/convkey/526d/w8lewdsdya7hley7g.jpg" alt="ocucaje al dia" />
                            <div className="msg">
                                <p>Buenos días. Estas son las noticias más importantes de la semana en Ocucaje.</p>
                                <br/>
                                <p>{paragraphs.p1}</p>
                                <br/>
                                <p>{paragraphs.p2}</p>
                                <br/>
                                <p>{paragraphs.p3}</p>
                                <br/>
                                <p>{paragraphs.p4}</p>
                                <br/>
                                <p>Entérate más ingresando a https://bit.ly/OcucajealDia</p>
                                <p>Si te gustó el contenido, ¡compártelo con tu familia y amigos!</p>
                            </div>
                            <p className="span">
                                TOTAL&nbsp;
                                {204+paragraphs.p1.length+paragraphs.p2.length+paragraphs.p3.length+paragraphs.p4.length} 
                                &nbsp;| MAX 1024
                            </p>
                        </div>
                        <form onSubmit={sendForm} className="form-msg">
                            <GetAcccountsContainer Loader={()=> <p>cargando..</p>}>
                            {
                                ({data}) => {
                                    return (
                                        <select className="form-select" name="accounts" defaultValue="">
                                            <option value="" disabled>Selecciona una cuenta</option>
                                            {data.map( (account) => <option key={account.account_id} value={account.account_id}> {account.name} </option> )}
                                        </select>
                                    )
                                }
                            }
                            </GetAcccountsContainer>
                            <GetListsContainer Loader={()=> <p>cargando..</p>}>
                                {
                                    ({data}) => {
                                        return (
                                            <select className="form-select" name="lists" defaultValue="">
                                                <option value="" disabled>Selecciona una lista</option>
                                                {data.map( (list) => <option key={list.id_list} value={list.id_list}> {list.namelist} </option>)}
                                            </select>
                                        )
                                    }
                                }
                            </GetListsContainer>
                            <TextArea 
                                name="p1" 
                                className="form-input" 
                                placeholder="Parrafo 1"
                                handleChange={ (txt)=> handleParagraphs("p1", txt) }
                            />
                            <TextArea 
                                name="p2" 
                                className="form-input" 
                                placeholder="Parrafo 2"
                                handleChange={ (txt)=> handleParagraphs("p2", txt) }
                            />
                            <TextArea 
                                name="p3" 
                                className="form-input" 
                                placeholder="Parrafo 3"
                                handleChange={ (txt)=> handleParagraphs("p3", txt) }
                            />
                            <TextArea 
                                name="p4" 
                                className="form-input" 
                                placeholder="Parrafo 4"
                                handleChange={ (txt)=> handleParagraphs("p4", txt) }
                            />
                            <p className="text-error">{error}</p>
                            {
                                loading 
                                ?  <SimpleLoader/>
                                : <BtnPrimary action={() => {}} text="ENVIAR"/>
                            }
                        </form>
                    </StyledContainerTemplateMessage>
                </StyledBody>
            </StyledContainerCreateShipment>
        </WrapperAdmin>
    )
}

export default CreateWhatsAppShipment