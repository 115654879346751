import axios from "axios";
import config from "../../../../../config";
import { CreateHeader } from "../../../../../utils/Http";

const URL = config.API_URL

export const getDetailListNumbers = async (idList: string) => {
    const header = CreateHeader();
    const resp = await axios.get(`${URL}/api/phones/getDetailListPhone/${idList}`,header)
    if(resp.status == 200) return resp.data;
    return {};
}