import { Account } from "../../../../interfaces/Admin.interfaces"
import { StyledBodyCardAccount, StyledCardAccount } from "./styles"

const CardAccount = ({ url_photo, name, IDmetaAccount  }: Account) => {
    return (
        <StyledCardAccount>
            {
                url_photo
                    ? <img src={url_photo} alt={name} />
                    : <img
                        src={`https://ui-avatars.com/api/?name=${name.split(" ")[0]}+${name.split(" ")[1]}&background=random&color=white`}
                        alt={name} />
            }
            <StyledBodyCardAccount>
                <p className="title"> {name} </p>
                <p className="subtitle"> ID META: {IDmetaAccount} </p>
            </StyledBodyCardAccount>
        </StyledCardAccount>
    )
}

export default CardAccount