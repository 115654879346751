import Logo from "../../components/Logos/Logo"
import InputPassword from "./components/Inputs/InputPassword"
import { StyledButton } from "./components/Inputs/styles"
import { StyledContainerLogin } from "./styles"
import HandleLink from "../../containers/HandleLink";
import useForm from "../../hooks/useForm";
import { LoginForm } from "../../interfaces/Auth.interfaces";
import { sendLogin } from "../../services/Auth.services";
import Cookies from "js-cookie";
import config from "../../config";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import SimpleLoader from "../../components/loaders/SimpleLoader";

const Login = () => {
    const navigate = useNavigate();
    const [error, setError] = useState();
    const [loading, setLoading] = useState(false);
    const { form,handleForm } = useForm<LoginForm>({
        email: "",
        password: ""
    })

    const sendForm = (e: React.MouseEvent) => {
        e.preventDefault()
        setLoading(true)
        sendLogin(form)
        .then( resp => {
            if(resp.status === 200){
                Cookies.set(config.TOKEN_AUTH, resp.data.token)
                navigate(0);
            }
        } )
        .catch( err => {
            setLoading(false)
            if(err.response && err.response.data){
                setError(err.response.data.message)
            }
        } )
    }

    if(loading){
        return(
            <StyledContainerLogin>
                <Logo width={80} height={80} />
                <SimpleLoader/>
            </StyledContainerLogin>
        )
    }

    return(
        <StyledContainerLogin>
            <Logo width={80} height={80} />
            <p className="title">Iniciar sesion</p>
            <form>
                <input 
                onChange={handleForm}
                value={form.email} className="form-input" 
                placeholder="Correo: " type="email" 
                name="email" autoComplete="off" 
                />
                <InputPassword 
                 name="password"
                 handleChange={handleForm}
                 placeholder="Contraseña: "
                 value={form.password}
                 style="form-input"
                />
                <p className="text-error"> {error} </p>
                <StyledButton onClick={sendForm}>Ingresar</StyledButton>
            </form>
            <p>¿No tienes cuenta?&nbsp;<HandleLink href="/register"><span>Registrate</span></HandleLink></p>
        </StyledContainerLogin>
    )
}

export default Login