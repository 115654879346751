import WrapperAdmin from "../../components/wrappers/WrapperAdmin"
import BtnAddAccount from "./components/BtnAddAccount"
import WrapperAccounts from "./components/WrapperAccounts"
import { StyledContainerAccounts, StyledHeaderAccounts } from "./styles"

const Accounts = () => {
    return(
        <WrapperAdmin>
            <StyledContainerAccounts>
                <StyledHeaderAccounts>
                    <p>MIS CUENTAS</p>
                    <BtnAddAccount/>
                </StyledHeaderAccounts>
                <WrapperAccounts/>
            </StyledContainerAccounts>
        </WrapperAdmin>
    )
}

export default Accounts